import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="" className="App-logo" alt="🙂" />
        <p>
          Coming soon
        </p>

      </header>
    </div>
  );
}

export default App;
